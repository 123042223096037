<template>
  <v-row justify="center">
      <v-col cols="12" md="10" lg="8">
        <v-banner>
          <!-- <v-avatar slot="icon" color="deep-purple accent-4" size="40"> -->
          <!-- <v-icon icon="mdi-lock" color="white">
              mdi-account-alert
            </v-icon> -->
          <!-- </v-avatar> -->
          <v-icon slot="icon" color="warning" size="36">
            mdi-account-alert-outline
          </v-icon>
          Are you one of the {{ business.custtype.toLowerCase() }} of
          {{ business.name }}? Sign in now to view your records.
          <template v-slot:actions>
            <v-btn color="primary" text @click="showSignIn = true">
              Sign In
            </v-btn>
          </template>
          <sign-in-dialog
            v-if="showSignIn"
            @close="showSignIn = false"
          ></sign-in-dialog>
        </v-banner>
      </v-col>
      <v-col cols="12" md="10" lg="8">
        <business-profile></business-profile>
      </v-col>
    </v-row>
</template>
<script>
import BusinessProfile from "@/components/BusinessProfile"

export default {
  data() {
    return {
      showSignIn: false,
    };
  },
  computed: {
    fpUser() {
      return this.$store.state.fp_user;
    },
    business() {
      return this.$store.state.business;
    },
  },
  components: {
    BusinessProfile,
    SignInDialog: () => import(/* webpackChunkName: "SignInDialog" */ "@/components/SignInDialog"),
  }
}
</script>